<template>
	<div class="pui-form">
		<contact-modals :modelName="modelName"></contact-modals>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<pui-field-set :id="'portid-contact-fs'" :title="$t('contact.contact')">
						<v-row class="align-center">
							<!-- PORTID -->
							<v-col cols="6">
								<pui-select
									id="portid-contact"
									attach="portid-contact"
									:label="$t('contact.portid')"
									toplabel
									clearable
									required
									v-model="model"
									modelName="port"
									:disabled="formDisabled"
									:modelFormMapping="{ id: 'portid' }"
									:itemsToSelect="[{ id: model.portid }]"
									:itemValue="['id']"
									:itemText="(item) => `${item.portname}`"
									:order="{ description: 'asc' }"
									:fixedFilter="filterByPortAuthority"
								></pui-select>
							</v-col>
							<!-- DISABLE -->
							<v-col cols="2">
								<pui-checkbox
									:label="$t('contact.disable')"
									v-model="model.disable"
									true-value="1"
									false-value="0"
									:disabled="formDisabled"
								></pui-checkbox>
							</v-col>
						</v-row>
					</pui-field-set>
				</v-col>
			</v-row>
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<pui-field-set :id="'nif-contact-fs'" :title="$t('contact.contactData')">
						<v-row dense>
							<!-- NIF -->
							<v-col cols="2">
								<pui-text-field
									:id="`nif-contact`"
									v-model="model.nif"
									:label="$t('contact.nif')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="35"
								></pui-text-field>
							</v-col>
							<!-- NAME -->
							<v-col cols="3">
								<pui-text-field
									:id="`name-contact`"
									v-model="model.name"
									:label="$t('contact.name')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="100"
								></pui-text-field>
							</v-col>

							<!-- ADDRESS -->
							<v-col cols="3">
								<pui-text-field
									:id="`address-contact`"
									v-model="model.address"
									:label="$t('contact.address')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="200"
								></pui-text-field>
							</v-col>

							<!-- CITY -->
							<v-col cols="2">
								<pui-text-field
									:id="`city-contact`"
									v-model="model.city"
									:label="$t('contact.city')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="100"
								></pui-text-field>
							</v-col>
						</v-row>
						<v-row dense>
							<!-- COUNTRY -->
							<v-col cols="2">
								<pui-text-field
									:id="`country-contact`"
									v-model="model.country"
									:label="$t('contact.country')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="100"
								></pui-text-field>
							</v-col>
							<!-- POSTALCODE -->
							<v-col cols="2">
								<pui-number-field
									:id="`postalcode-contact`"
									v-model="model.postalcode"
									:label="$t('contact.postalcode')"
									required
									toplabel
									maxlength="5"
								></pui-number-field>
							</v-col>
							<!-- PHONENUMBER -->
							<v-col cols="2">
								<pui-number-field
									:id="`phonenumber-contact`"
									v-model="model.phonenumber"
									:label="$t('contact.phonenumber')"
									required
									toplabel
									maxlength="9"
									max="999999999"
								></pui-number-field>
							</v-col>
							<!-- EMAIL -->
							<v-col cols="3">
								<pui-text-field
									:id="`email-contact`"
									v-model="model.email"
									:label="$t('contact.email')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="200"
								></pui-text-field>
							</v-col>
							<!-- COMPANYID -->
							<v-col cols="3">
								<pui-select
									id="companyid-contact"
									attach="companyid-contact"
									:label="$t('contact.companyid')"
									toplabel
									clearable
									required
									v-model="model"
									modelName="company"
									:modelFormMapping="{ id: 'companyid' }"
									:itemsToSelect="[{ id: model.companyid }]"
									:itemValue="['id']"
									:itemText="(item) => `${item.name}`"
									:order="{ description: 'asc' }"
									:fixedFilter="filterByPort"
								></pui-select>
							</v-col>
						</v-row>
					</pui-field-set>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import contactActions from './ContactActions';
import contactModals from './ContactModals.vue';

export default {
	name: 'contact-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'contact-modals': contactModals
	},
	data() {
		return {
			modelName: 'contact',
			actions: contactActions.formActions
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		}
	},
	computed: {
		filterByPortAuthority() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'portauthorityid', op: 'eq', data: this.$store.getters.getPortAuthorityId }]
			};
		},
		filterByPort() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'portid', op: 'eq', data: this.model.portid }]
			};
		}
	},
	watch: {
		'model.portid': {
			handler: function (newValue) {
				if (typeof newValue === 'undefined' || newValue == null) {
					this.model.portauthorityid = null;
				} else {
					this.model.portauthorityid = this.$store.getters.getPortAuthorityId;
				}
			}
		}
	},
	created() {}
};
</script>
